import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors, GreenBtn, GreyBtn, md } from '../common'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import JoinUs from '../sections/joinUs'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Layout>
    <SEO
      title="Financing"
      description="There are two options of interest free financing at Underwood Jewelers. You can either apply for an interest free AGS Wells Fargo account or you can open a ten-month interest free layaway." />
    <Wrap>
    <h1>Financing at Underwood Jewelers</h1>
    <div>
      <p>At Underwood’s, we want to see all of your dreams come true. We understand that fine jewelry, watches, diamonds and rings are big investments and therefore we proudly offer two options to accommodate your financial needs.</p>
      <h2>No Interest if Paid in Full within 12 months</h2>
      <p>Interest will be charged to your account from the purchase date if the purchase balance is not paid in full within the promotional period. <sup>1</sup></p>
      <p><sup>1</sup>
      The American Gem Society credit card is issued by Wells Fargo Bank, N.A. Special terms apply to qualifying purchases charged with approved credit. Minimum monthly payments are required during the promotional (special terms) period. Interest will be charged to your account from the purchase date at the APR for Purchases if the purchase balance is not paid in full within the promotional period. Paying only the minimum monthly payment will not pay off the purchase balance before the end of the promotional period. For new accounts, the APR for Purchases is 28.99%. If you are charged interest in any billing cycle, the minimum interest charge will be$1.00. •	This information is accurate as of 10/1/2019 and is subject to change. For current information call us at 1-800-431-5921. Offer expires 12/31/2020.
      </p>
      <div css={`display:flex;`}>
        <GreenBtn css={`margin:auto;`}><a css={`color:white`} target="_blank" href="https://retailservices.wellsfargo.com/pl/2114006638" rel="noopener noreferrer" >Apply Now &raquo;</a></GreenBtn>
      </div>
      <h2>Underwood’s 10-Month Layaway, Interest Free Program</h2>
      <p>There is no credit check required, all you need is 20% of the total purchase price down to qualify. This plan has a minimum monthly payment and is interest free if paid on time each month. Please note that the item being purchased will remain in our store until the balance is paid off in full. Unpaid monthly statements will accrue a finance charge. Certain items do not qualify for layaway purchases.</p>
      <p>Visit Our Stores for Details.</p>
      <div css={`display:flex;`}>
        <GreyBtn onClick={handleClick} style={{margin:'auto'}}>Contact&nbsp;Us &raquo;</GreyBtn>
      </div>
    </div>
    </Wrap>
    <JoinUs />
    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact />
    </Modal>
  </Layout>
)}

const Wrap = styled.div`
  padding:4rem;

  h1, h2 {
    text-align:center;
    color: ${colors.green};
    text-transform:uppercase;
    padding: 2rem 0;
  }

  ${md} {
    padding:1rem;
  }
`
